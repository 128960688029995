@import "euphoria/buttons";
@import "euphoria/responsive/breakpoints";
@import "euphoria/utils";
@include normalize;

$column-gutter-bottom: 1em;
$element-vertical-spacing: 1em;
$layout-width: 1170px;

@import "partials/functions";
@import "../../config/config";
@import "partials/typography";
@import "partials/layout";
@import "partials/fragments";
@import "partials/templates";

body {
	@extend %body-font;
	@extend %body;
}

.wf-loading {
	h1, h2, h3, h4, h5, h6, p, li {
		visibility: hidden;
	}
}

.content {
	margin: 0 auto;
	max-width: 1170px;
	padding: 0 1em;
    &.smaller {
        max-width:870px;
    }
}

.fg-element {
	margin: $element-vertical-spacing 0;

	.body & {
		min-height: 1em;
	}

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

#google_map img {
	max-width: none !important;
}

$fancybox-image-url: '../../images/fancybox';
@import "vendor/jquery.fancybox";

header {
    &.new-header {
        position:relative;
        border-bottom: 5px solid #f89d37;
        overflow:hidden;
        .background {
            position:absolute;
            width:100%;
            height:100%;
        }
        .text {
            position:relative;
            z-index:1;
            background: rgba(255, 255, 255, 0.9);
            border-radius: 5px;
            color: #5f5f5f;
            display:table;
            font-size: 1.84em;
            font-weight: 700;
            max-width: 57%;
            padding: 1.5em 1.2em;
            margin:2em 0;
            float:right;
            .fg-element {
                box-sizing: border-box;
            }
            @include breakpoint(phone-portrait) {
                padding: 0;
                margin: 2em auto;
                float:none;
                max-width: 0;
                width: 100%;
                .fg-element {
                    padding: .85em;
                }
                 .button {
                    margin-bottom:.5em;
                 }
             }
            h1,h2,h3,h4,h5,h6 {
                color:#5f5f5f;
            }
            p {
                font-size:18px;
                font-weight:500;
            }
            ul {
                margin-top:1em;
                margin-bottom:2em;
                li {
                    margin-bottom:.5em;
                }
            }
        }
    }
}

ul {
    &.checklist {
        margin:0;
        font-size:18px;
        font-weight:500;
        padding:0;
        list-style:none;
        li {
            &:before {
                position:relative !important;
                content:"\f00c";
                display:inline-block;
                vertical-align:middle;
                font:normal normal normal 16px/1 FontAwesome;
                top: inherit !important;
                right: inherit !important;
                color:$theme-primary-color;
                margin-right:.5em;
            }
        }
    }
    &.alt {
        margin:0;
        font-size:18px;
        font-weight:500;
        padding:0;
        list-style:none;
        li {
            &:before {
                position:relative !important;
                content:">";
                font-size:19px;
                display:inline-block;
                vertical-align:middle;
                top: inherit !important;
                right: inherit !important;
                color:$theme-primary-color;
                margin-right:.5em;
            }
        }
    }
}
body ul li:before {
    font-size: 2em;
    margin-top:-.25em;
}
ol {
    list-style:none;
}
.some-fields-required {
    display:none;
}
