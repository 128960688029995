.region {
	border: 1px solid #e3e3e3;
	border-width: 1px 0;
	padding: 4.2em 0;

	&.dark {
		background: #f8f8f8;
	}

	&.cta {
		background: $theme-primary-color;
	}

	&:first-child, .full-width + &, &.region + .region {
		border-top-width: 0;
	}

	&:last-child {
		border-bottom-width: 0;
	}

	& + .full-width, & + .full-width-2-col, & + .full-width-2-col-alt {
		background: #fff;
		margin-top: -1px;
		position: relative;
		z-index: 1;
	}

	&.dark {
		& + .full-width, & + .full-width-2-col, & + .full-width-2-col-alt {
			background: #f8f8f8;
		}
	}

	.fragment {
		background: inherit;
	}

	.full-width + &, .full-width-2-col + &, .full-width-2-col-alt + & {
		margin-top: -$fragment-spacing;
	}
}
