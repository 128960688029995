$theme-primary-color: #f89d37;
$theme-secondary-color: #349bd4;

$button-background: $theme-primary-color;
$column-gutter: 2.4em;
$eresults-branding: true;
$font-color: #5f5f5f;
$font-family: "Open Sans", Arial, sans-serif;
$font-size: 17px;
$font-weight: normal;
$header-font-color: null;
$header-font-family: null;
$header-font-weight: null;
$h1-font-color: null;
$h1-font-weight: null;
$h2-font-color: null;
$h2-font-weight: 900;
$h3-font-color: null;
$h3-font-weight: null;
$h4-font-color: null;
$h4-font-weight: 300;
$h5-font-color: null;
$h5-font-weight: 800;
$h6-font-color: null;
$h6-font-weight: null;
$footer-background-color: #fafafa;
$footer-link-color: $theme-primary-color;
$footer-cta-background-color: $theme-primary-color;
$footer-cta-link-color: #fff;
$footer-newsletter-background-color: $theme-primary-color;
$footer-newsletter-link-color: $footer-link-color;
$footer-header-font-color: null;
$logo-height: 1.6;
$menu-horizontal-spacing: null;
$menu-font-color: null;
$menu-font-weight: null;
$menu-active-font-color: null;
$menu-active-font-weight: null;
$fragment-spacing: 2em;
$link-color: #37b0e9;
$list-ordered-icon-color: $theme-primary-color;
$list-unordered-icon-color: $theme-primary-color;
$list-unordered-checks-icon: url('../../images/list-unordered-icon.png');
$makers-mark-link-color: rgba( $font-color, .35 );
$makers-mark-link-hover-color: $font-color;
