.mod-detail {
	&.element-template--lightbox-tiles,
	&.element-template--lightbox-rows {
		.items {
			.item {
				box-sizing: border-box;
				position: relative;
				overflow: hidden;
				background: #fff;
				border: 1px solid #e3e3e3;
				border-radius: 3px;

				.image {
					box-sizing: border-box;
					position: relative;
					overflow: hidden;

					img {
						width: 100%;
						height: auto;
						display: block;
					}

					.counter {
						box-sizing: border-box;
						display: inline-block;
						position: absolute;
						bottom: 0;
						right: 0;
						color: #fff;

						.image-count {
							box-sizing: border-box;
							position: relative;
							line-height: 0;
							display: block;
							padding: .8em;
							border-radius: 3px;

							&:before {
								box-sizing: border-box;
								content:'';
								display: inline-block;
								background: {
									image: url('../../images/photo.svg');
									repeat: no-repeat;
									size: 25px 20px;
									position: center;
									color: rgba( $theme-primary-color, .8 );
								};
								width: 45px;
								height: 40px;
								margin-right: .3em;
								padding: .3em;
								border-radius: 3px;
							}
						}

						.number {
							position:relative;
							top: -12px;
						}
					}
				}

				.text {
					box-sizing: border-box;
					position: relative;
					padding: 1.5em 1.75em;
				}
			}
		}
	}

	&.element-template--lightbox-tiles {
		.items {
			@include basic-columns(
				$count: 2,
				$selector: '.item',
				$gutter: ( 2.4em, 3.846% ),
				$gutter-bottom: 2.4em,
				$breakpoint: phone-landscape
			);
		}
	}

	&.element-template--lightbox-rows {
		.items {
			.item {
				margin-bottom: 2.4em;

				&:last-child {
					margin-bottom: 0;
				}

				@include basic-columns(
					$count: 2,
					$selector: '.part',
					$dimensions: ( 40%, 60% ),
					$gutter: ( 0, 0 ),
					$gutter-bottom: 0,
					$breakpoint: phone-landscape
				);



				.text {
					padding: 2.75em 2.5em;

					@include phone-landscape {
						padding: 1.75em 1.5em;
					}

					h2 {
						margin-bottom: .6em;
					}

					.inner-text {
						.controls {
							.image-count {
								position: relative;
								margin-right: .5em;
								font-weight: bold;
								font-size: .8em;
								text-transform: uppercase;

								&:before {
									content: '';
									display: inline-block;
									box-sizing: border-box;
									background: {
										image: url('../../images/photo-text.svg');
										repeat: no-repeat;
										size: 25px 20px;
										position: center;
									};
									width: 25px;
									height: 20px;
									margin-right: .2em;
									position: relative;
									top: 2px;
								}
							}
						}
					}
				}

				@include phone-landscape {
					.part:nth-child( 1n ) {
						margin: 0;
					}
				}
			}
		}
	}

	&.element-template--default, &.element-template--two-col, &.element-template--three-col {
		.item {
			@include clearfix;
			border: 1px solid #e7e7e7;
			border-radius: 5px;
			position: relative;
			overflow: hidden;
		}

		h3 a {
			color: $theme-primary-color;
			text-decoration: none;
		}

		time {
			display: block;
			font-weight: 700;
			margin-top: -.5em;
		}

		.image {
			background-size: cover;
			background-position: center;
			display: block;
		}

		.text {
			box-sizing: border-box;
			padding: 1.84em 2.63em;

			@include breakpoint(phone-landscape) {
				padding: 1.2em 1.4em 1.3em;
			}
		}

		.detail {
			@include clearfix;

			.text {
				float: left;
				padding: 0;
				width: 60%;
			}

			.images {
				float: left;
				width: 40%;

				img {
					border-radius: 5px;
					width: 100%;
				}

				.primary-image {
					display: block;
					margin-bottom: 1em;

					img {
						display: block;
					}
				}

				.thumbnails {
					@include clearfix;

					.image {
						background-size: cover;
						background-position: center;
						border-radius: 5px;
						display: block;
						float: left;
						margin: 0 1em 1em 0;
						width: calc(33.33% - .67em);
						padding-bottom: calc(33.33% - .67em);
						position: relative;

						img {
							visibility: hidden;
							position: absolute;
						}

						&:nth-child(3n+3) {
							margin-right: 0;
						}

						&:nth-child(3n+4) {
							clear: both;
						}
					}
				}
			}
		}
	}

	&.element-template--default {
		.item {
			margin-bottom: $column-gutter-bottom;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.image {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 40%;
		}

		.text {
			float: left;
			width: 60%;
		}

		@include breakpoint(phone-landscape) {
			.image {
				position: static;
				height: 14em;
				width: auto;
			}

			.text {
				float: none;
				width: auto;
			}
		}
	}

	&.element-template--two-col, &.element-template--three-col {
		h3 {
			font-size: 1.31em;
		}

		.text {
			padding: 1.47em 1.68em;
		}
	}

	&.element-template--five-col {

	   h3 {
		   font-size: 1em;
	   }

	   .text {
		   text-align:center;
		   padding: 1.47em 1em;
	   }
	}

	&.element-template--two-col {
		.image {
			height: 18em;
		}

		.items {
			@include basic-columns($count: 2, $selector: '.item', $breakpoint: phone-landscape);
		}

		.last-row-count-1 .item:nth-last-child(1) {
			margin-left: calc(25% + .25em);

			@include breakpoint(phone-landscape) {
				margin-left: 0;
			}
		}
	}

	&.element-template--three-col {
		.image {
			height: 14em;
		}

		.items {
			@include basic-columns($count: 3, $selector: '.item', $breakpoint: phone-landscape);
		}

		.last-row-count-2 .item:nth-last-child(2) {
			margin-left: calc(16.5% + .5em);

			@include breakpoint(phone-landscape) {
				margin-left: 0;
			}
		}

		.last-row-count-1 .item:nth-last-child(1) {
			margin-left: calc(33.5% + .25em);

			@include breakpoint(phone-landscape) {
				margin-left: 0;
			}
		}
	}

	&.element-template--five-col {

		.image {
			height: 14em;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
		}

		.items {
			@include basic-columns($count: 5, $selector: '.item', $breakpoint: phone-landscape);
		}

		.last-row-count-4 .item:nth-last-child(4) {
			margin-left: calc(10% + .5em);

			@include breakpoint(phone-landscape) {
				margin-left: 0;
			}
		}

		.last-row-count-3 .item:nth-last-child(3) {
			margin-left: calc(20% + .5em);

			@include breakpoint(phone-landscape) {
				margin-left: 0;
			}
		}

		.last-row-count-2 .item:nth-last-child(2) {
			margin-left: calc(30% + .5em);

			@include breakpoint(phone-landscape) {
				margin-left: 0;
			}
		}

		.last-row-count-1 .item:nth-last-child(1) {
			margin-left: calc(41% + .25em);

			@include breakpoint(phone-landscape) {
				margin-left: 0;
			}
		}
	}
}
