@import "../functions/assign-inputs";

$button-border: none !default;
$button-background: #666 !default;
$button-hover-background: null !default;
$button-text-color: #fff !default;
$button-hover-text-color: null !default;
$button-padding: .6em 1.1em !default;
$button-border-radius: 5px !default;
$button-transition: all .2s !default;

$buttons-list: '.button',
			   'button',
               'input[type="button"]',
               'input[type="reset"]',
               'input[type="submit"]';

$all-buttons:        assign-inputs($buttons-list);
$all-buttons-active: assign-inputs($buttons-list, active);
$all-buttons-focus:  assign-inputs($buttons-list, focus);
$all-buttons-hover:  assign-inputs($buttons-list, hover);

@mixin button(
	$background: $button-background,
	$text-color: $button-text-color,
	$hover-background: $button-hover-background,
	$hover-text-color: $button-hover-text-color
) {
	background: $background;
	border: $button-border;
	border-radius: $button-border-radius;
	color: $text-color;
	display: inline-block;
	margin: 0;
	padding: $button-padding;
	text-decoration: none;
	transition: $button-transition;

	&:hover {
		color: $hover-text-color;

		@if $hover-background == null {
			background: lighten( $background, 5% );
		}

		@if $hover-text-color == null {
			color: $text-color;
		}
	}

	&:active {
		outline: none;
	}

	@content;
}
